<template>
  <div>
    <v-container
      v-if="componentKey"
      class="container--fluid"
    >
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col
              cols="12"
              class="pl-0 pr-0 pb-1"
            >
              <v-row>
                <v-spacer />

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="measureSelect"
                    :items="measures"
                    label=""
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-6"
                >
                  <v-btn
                    color="primary"
                    @click="heatmaps"
                  >
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-col
                cols="12"
                class="col-md-15 text-center"
              >
                <b>{{ santralModel }}</b>
              </v-col>
              <div
                id="d3jstotaldccurrent"
                class="pb-6"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as Heatmap from '@/api/CombinerBox/Heatmap';
import * as d3 from 'd3';
import i18n from '@/locale';
import moment from 'moment';
import common from '@/api/component/common.js';

import { getStringboxList } from '@/api/Request/stringbox';

export default {
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      menu: false,
      modal: false,
      menu2: false,
      measures: [],
      measureSelect: 'DCCurrent',
      componentKey: 1,
      title: '',
      santralModel: '',
      inverterList: [],
      height: '',
      HeatmapData: [],
    };
  },
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },
  },
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const StatsMeasure = self.$store.getters.santralInfo;

      if (santral !== undefined && Object.keys(StatsMeasure).length > 0) {
        self.santralModel = santral;
        self.santralInfo = StatsMeasure;

        const stringbox = self.santralInfo[self.santralModel].STRINGBOX;
        const measureList = [];
        const _measureList = [];
        const typeList = ['avg', 'sum'];
        Object.keys(stringbox).forEach((com) => {
          const tempMeasureList = stringbox[com].general_measure_list;

          tempMeasureList.forEach((measure) => {
            if (_measureList.indexOf(measure.value) < 0) {
              if (typeList.indexOf(measure.formuletype) > -1) {
                measureList.push({
                  text: i18n.t(measure.text),
                  value: measure.value,
                  unit: measure.unit,
                  type: measure.type,
                });
                _measureList.push(measure.value);
              }
            }
          });
        });
        clearInterval(myVar1);
        self.measures = measureList;
        self.heatmaps();
      }
    }
  },
  methods: {
    heatmaps() {
      document.getElementById('d3jstotaldccurrent').innerHTML = '';
      const stepTime = this.santralInfo[this.santralModel].dataLoggerTimeInterval;

      const minuteGrup = [];
      for (let m = 0; m < 60; m += (stepTime * 3)) {
        minuteGrup.push(m);
      }
      const { stringboxInfo } = this.$store.getters;

      const stringList = [];
      const stringboxNameList = [];

      Object.keys(stringboxInfo[this.santralModel]).forEach((item) => {
        stringboxNameList.push(
          stringboxInfo[this.santralModel][item].STRINGBOXNAME,
        );
        stringList.push(stringboxInfo[this.santralModel][item].STRINGBOX);
      });

      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.startDate,
          measures: this.measureSelect,
          STRINGBOX: stringList,
          maxcount: 3000,
          MinuteGrup: minuteGrup,
        },
      };

      const margin = {
        top: 10,
        right: 25,
        bottom: 80,
        left: 250,
      };
      const strsize = stringList.length;
      console.log(strsize);

      const width = 1200 - margin.left - margin.right;
      const height = strsize * 20;
      console.log(height);
      const svg = d3
        .select('#d3jstotaldccurrent')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const tempdata = getStringboxList(formdata);
      tempdata.then((res) => {
        const temp = Heatmap.default.heatmapWeeklyHourMeans_combiner_diff(
          res,
          this.measureSelect,
          stringboxInfo[this.santralModel],
          stringboxNameList,
        );
        temp.then((data) => {
          const myGroups = d3.map(data, (d) => d.time).keys();
          const myVars = d3.map(data, (d) => d.STRINGBOXNAME).keys();

          const x = d3
            .scaleBand()
            .range([0, width])
            .domain(myGroups)
            .padding(0.05);

          svg
            .append('g')
            .style('font-size', 12)
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '-.8em')
            .attr('dy', '-.5em')
            .attr('transform', 'rotate(-85)');

          // Build Y scales and axis:
          const y = d3
            .scaleBand()
            .range([height, 0])
            .domain(myVars)
            .padding(0.05);
          svg
            .append('g')
            .style('font-size', 12)
            .call(d3.axisLeft(y).tickSize(0))
            .select('.domain');

          const toolTip = d3
            .select('#d3jstotaldccurrent')
            .append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('text-align', 'center')
            .style('padding', '30px')
            .style('background', '#24b1d1')
            .style('border', '0px')
            .style('border-radius', '8px')
            .style('opacity', 0);

          const mouseover = function (d) {
            toolTip.transition().duration(1).style('opacity', 0.9);
            toolTip
              .html(d.html)
              .style('left', `${d3.event.pageX}px`)
              .style('top', `${d3.event.pageY + 200}px`);
          };

          const { startDate } = this;
          const { measureSelect } = this;
          let s_measure;
          let unit = '';
          this.measures.forEach((measure) => {
            if (this.measureSelect === measure.value) {
              unit = measure.unit;
              s_measure = measure;
            }
          });

          const mousemove = function (d) {
            const tempVal = common.setmeasures(d.mean, 2);
            const value = common.setmeasures(d.value, 2);

            toolTip
              .html(
                `${startDate} ${d.time} <br> ${d.STRINGBOXNAME}<br> ${measureSelect} : ${value} ${unit} <br> Ortalama : ${tempVal} ${unit}`,
              )
              .style('left', `${d3.mouse(this)[0]}px`)
              .style('top', `${d3.mouse(this)[1] + 10}px`);
          };
          const mouseleave = function (d) {
            toolTip.style('opacity', 0);
            d3.select(this).style('stroke', 'none').style('opacity', 0.9);
          };

          svg
            .selectAll()
            .data(data, (d) => `${d.time}:${d.STRINGBOXNAME}`)
            .enter()
            .append('rect')
            .attr('font-size', 11)
            .attr('x', (d) => x(d.time))
            .attr('y', (d) => y(d.STRINGBOXNAME))
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', x.bandwidth())
            .attr('height', y.bandwidth())
            .style('fill', (d) => d.color)
            .style('stroke-width', 4)
            .style('stroke', 'none')
            .style('opacity', 0.8)
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseleave', mouseleave);
        });
      });
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 180px;
  min-height: 70px;
  padding: 2px;
  font: 16px sans-serif;
  border: 1px solid black;
  border-radius: 8px;
  pointer-events: none; /* keep the mouseover when over the tooltip */
}

.subtitle {
  font-family: sans-serif;
  font-size: 20px;
}

.legendPointText {
  font-size: 10px;
}

#footer {
  color: black;
  text-align: center;
}
#footer a {
  color: blue;
}
</style>
